// https://www.gatsbyjs.org/docs/adding-tags-and-categories-to-blog-posts/#add-tags-to-your-markdown-files

import React from 'react';
import { Link, graphql } from 'gatsby';

import { kebabCase } from 'lodash';

import Layout from '../components/layout';
import SEO from '../components/seo';
const OriginsPage = ({ data }) => {
    const allOrigins = data.allMarkdownRemark.group
    const sortedOrigins = allOrigins.sort((first, second) => second.totalCount - first.totalCount);

  return (
    <Layout>
        <SEO 
          title="Snoffeecob Blogs by Origin"
          description="Snoffeecob Blogs by Origin"
        />
      <div className="blog-tags">
        <h1>Snoffeecob reviews by origin</h1>
        <ul className="tags">
          {sortedOrigins.map(origin => (
            <li key={origin.fieldValue}>
              <Link to={`/origins/${kebabCase(origin.fieldValue)}/`} className="tag">
                {origin.fieldValue} ({origin.totalCount})
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </Layout>
  );
};

export default OriginsPage;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(limit: 2000) {
      group(field: frontmatter___origin) {
        fieldValue
        totalCount
      }
    }
  }
`;
